.btn-toggle-zi {
    background-color: var(--color-bg-stats);
    color: var(--color-bg);
    position: absolute;
    bottom: 5px;
    height: 28px;
    width: 28px;
    display: block;
    z-index: 1;
    border-radius: 50%;
    border-width: 0;
    padding: 0;
}

.zi-on {
    background-color: var(--color-cyan);
}

#zi-2{
    left: 35px;
}
#zi-20{
    left: 65px;
}
#zi-100{
    left: 95px;
}

#zi-Laval{
    left: 125px;
}
