/*.declaration-order {*/
/*  !* - - - - Positioning - - - - *!*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  z-index: 100;*/
/*  display: block;*/

/*  !* - - - - Box-model - - - - *!*/
/*  float: right;*/
/*  width: 100px;*/
/*  height: 100px;*/

/*  !* - - - - Typography - - - - *!*/
/*  font-family: sans-serif;*/
/*  line-height: 1.5;*/
/*  color: #333;*/
/*  text-align: center;*/

/*  !* - - - - Appearance - - - - *!*/
/*  background-color: #f5f5f5;*/
/*  border: 1px solid #e5e5e5;*/
/*  border-radius: 3px;*/

/*  !* - - - - Jazz - - - - - - - *!*/
/*  animation: 100ms all linear;*/
/*}*/

/* --- Mobile First ---

Breaking points: 480px, 720px, 960px, 1024px

--- Design Units ---

  font units: rem
  margin, padding: min-value: px
  height, width: vh vw

  max-width for the content 1220px
*/
@font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/Nunito/Nunito-SemiBold.ttf"); /* IE9 Compat Modes */
}

@font-face {
    font-family: "Prompt-Bold";
    src: url("./assets/fonts/Prompt/Prompt-Bold.ttf"); /* IE9 Compat Modes */
}

@font-face {
    font-family: "Prompt-Italic";
    src: url("./assets/fonts/Prompt/Prompt-Italic.ttf"); /* IE9 Compat Modes */
}

@font-face {
    font-family: "Prompt-Regular";
    src: url("./assets/fonts/Prompt/Prompt-Regular.ttf"); /* IE9 Compat Modes */
}

/* --- Define CSS variables --- */
:root {
    --font-prompt: "Prompt-Regular", sans-serif;
    --font-prompt-italic: "Prompt-Italic", sans-serif;
    --font-prompt-bold: "Prompt-Bold", sans-serif;
    --font-nunito: "Nunito", sans-serif;
    --font-size-primary: 16px;
    --color-geosapiens-blue: #27286f;
    --color-geosapiens-green: #b9cc8f;
    --color-blue-light: #007ac2;
    --color-bg: white;
    --color-black: black;
    --color-cyan: #16ccbf;
    --color-pink: #f4bfca;
    --color-dark-pink: #fd6685;
    --color-red: #fe1b1b;
    --color-yellow: #f0de13;
    --color-orange: #ff9404;
    --color-bg-stats: #083f62;
    --color-seperator: #37607a;
    --color-light-black: #2d271e;
    --color-light-grey: #f2f2f2;
    --color-medium-grey: #9a9a9a;
    --color-disabled: #c0c0c0;
    --color-green: #00837a;
}

/* --- Reset CSS propreties --- */
* {
    box-sizing: border-box;
    outline: none;
}

html,
body {
    font-family: var(--font-prompt) !important;
    margin: 0;
    width: 100vw;
    overflow-x: hidden;
    padding: 0;
}

button {
    cursor: pointer !important;
}

.app {
    display: flex;
    position: static;
    text-align: left;
    flex-direction: column;
    background-color: var(--color-bg);
    background-image: none;
    background-size: auto;
    justify-content: flex-start;
}

.header_logo {
    width: 200px;
    margin: 0.9375rem;
}

.header {
    align-items: flex-start;
    background-color: var(--color-bg);
    display: flex;
    height: 28vh;
    justify-content: space-around;
    flex-wrap: wrap;
    z-index: 1;
    width: 100%;
    top: 0;
    background-image: none;
}

.slider_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    flex-grow: 2;
}

.slider_header_container {
    position: relative;
    width: 80%;
    min-height: 125px;
    background-color: white;
    z-index: 1;
    border-radius: 0.375rem;
    margin-top: 0.625rem;
    padding: 0.7rem 0.5rem 0;
    /*padding-bottom: 0;*/
}

.slider_header_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.slider_header_container_wrap {
    position: relative;
    width: calc(100% - 100px);
}

.header_slider {
    height: auto;
    width: 120px;
    position: relative;
}

.header_slider_input_scale {
    position: absolute;
    border-left: 2px solid #c0c0c0;
    color: #c2c2c2;
    font-weight: 400;
    line-height: 1.43;
    white-space: nowrap;
    letter-spacing: 0.01071em;
    padding-left: 0.125rem;
    font-family: var(--font-prompt-italic);
    font-size: 0.625rem;
    top: -0.25rem;
}

.header_slider_input_scale:first-of-type {
    border-left: transparent;
}

.header_slider_input {
    width: 68%;
}

.header_slider_text {
    top: 0.3125rem;
    left: 0.9375rem;
    width: 120px;
    color: var(--color-light-black);
    font-size: 0.75rem;
    font-weight: bold;
    min-width: 120px;
    padding: 0.375rem;
    text-align: center;
    display: flex;
    justify-content: start;
    align-items: center;
    border-right: 2px solid var(--color-disabled);
    border-bottom: 4px solid var(--color-disabled);
}

.header_slider_text_below {
    top: 2.5rem;
    left: 0.9375rem;
    width: 120px;
    color: var(--color-light-black);
    font-size: 0.75rem;
    font-weight: bold;
    min-width: 120px;
    padding: 0.5rem;
    text-align: center;
    display: flex;
    justify-content: start;
    align-items: center;
    line-height: 1;
    border-right: 2px solid var(--color-disabled);
}

.header_slider_text svg,
.header_slider_text_below svg {
    width: 19px !important;
    height: 14px !important;
}

.typeMesureContainer {
    padding-left: 0.625rem;
}

.header_actions {
    /*display: flex;*/
    display: none;
    justify-content: flex-start;
    padding-top: 2.55rem;
}

.gestion_crise {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 96%;
    padding-top: 0.35rem;
}

#btn_login {
    height: 40px;
    width: 200px;
    font-family: var(--font-prompt-bold);
    font-size: 1rem;
    line-height: 1.125rem;
    color: var(--color-bg);
    /*padding-left: 0.3125rem;*/
    background-color: var(--color-green);
    border: none;
    border-radius: 0.375rem;
    padding: 0.3125rem 0.5625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /*pointer-events: none;*/
    cursor: pointer;
    z-index: 10;
    margin: auto;
}

#btnCrise {
    font-family: var(--font-prompt-bold);
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--color-bg);
    background-color: var(--color-green);
    border: none;
    border-radius: 0.375rem;
    padding: 0.3125rem 0.5625rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

#btnManualMode {
    font-family: var(--font-prompt-bold);
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--color-bg);
    background-color: var(--color-green);
    border: none;
    border-radius: 0.375rem;
    padding: 0.3125rem 0.5625rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

#div-manual {
    height: 44px;
    width: 190px;
    background-color: var(--color-bg);
    top: 100px;
    right: 10px;
}
/*remove undo/redo*/
.esri-sketch__section.esri-sketch__tool-section:last-of-type {
    display: none;
}
.esri-sketch__section.esri-sketch__tool-section:nth-child(2) {
    border-right: none;
}

.btnModeCriseTrue > path {
    fill: var(--color-green);
}
.btnModeCriseFalse > path {
    fill: var(--color-bg);
}

#btnCrise svg {
    margin: 0 5px;
}

.btn_signout {
    width: 48px;
    height: 48px;
    background-color: var(--color-light-grey) !important;
    border: none !important;
}

.btn_signout .fa-power-off {
    color: var(--color-black) !important;
    transition: 0.5s ease-in-out;
}

.btn_signout:hover .fa-power-off {
    color: var(--color-cyan) !important;
}

.map {
    height: 72vh;
    width: 100vw;
    z-index: 0;
}

.panel {
    position: absolute;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 16px;
    flex-direction: column;
    z-index: 1;
    background-image: none;
}

.left-panel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 700px;
    /*transition: 0.5s ease-in-out;*/
}

.left-panel-stat-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 0.3125rem;
    line-height: 1.25;
}

.left-panel-stat-container.stats,
.left-panel-stat-container.search {
    flex-grow: 1.25;
}

.left-panel-stat-container h3 {
    color: var(--color-black) !important;
    font-size: 0.75rem;
    text-align: center;
}

.stats-panel {
    display: none;
    left: 0.9375rem;
    bottom: 4.0625rem;
    position: fixed;
    z-index: 1;
}

#btnIncertMin,
#btnIncertMax {
    font-family: var(--font-nunito);
    font-size: 1.375rem;
}

#btn-search-ctrl {
    cursor: pointer;
}

.btnTrue {
    width: 64px;
    height: 64px;
    background-color: var(--color-bg);
    border-radius: 50%;
    border: none;
    font-size: 1.5rem;
    color: var(--color-cyan);
}

.btnFalse {
    width: 64px;
    height: 64px;
    background-color: var(--color-bg);
    border-radius: 50%;
    border: none;
    font-size: 1.5rem;
    color: var(--color-disabled);
}

.btnCriseTrue {
    background-color: transparent !important;
    color: var(--color-green) !important;
}

.btnCriseFalse {
    background-color: var(--color-green) !important;
    color: var(--color-bg) !important;
}

/* #btnStats svg path, #btnWater svg path, #btnBuilding svg path, #btnStreet svg path, #btnBag svg path, #btnIncertMin svg path, #btnIncertMax svg path, #btn-search-ctrl svg path{
    fill: var(--color-cyan);
} */

/*Media query*/
@media only screen and (min-width: 480px) {
    .header {
        height: 20vh;
    }

    .map {
        height: 80vh;
    }

    .block_stats_graph {
        /* left: 90px;
            justify-content: flex-start; */
        justify-content: center;
        bottom: -200px;
    }
}

@media only screen and (min-width: 812px) {
    .header {
        height: 3rem;
    }

    .map {
        height: 84vh;
    }
}

@media only screen and (max-width: 815px) {
    .header_logo {
        width: 150px !important;
    }
}

@media only screen and (max-width: 1024px) {
    div.map.esri-view {
        height: calc(100vh - 180px) !important;
    }

    .panel {
        position: fixed;
        width: 80%;
        margin: 0 auto;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .header {
        height: 180px;
        display: block;
        z-index: 1;
    }

    .slider_container {
        width: 100%;
    }

    .slider_header_container {
        width: inherit;
        min-height: auto;
        margin-top: 0;
    }

    .slider_header_container_wrap {
        width: 100%;
    }

    .header_slider {
        width: 2.5rem;
    }

    .header_slider_text,
    .header_slider_text_below {
        width: 2.5rem;
        min-width: 2.5rem;
    }

    .header_slider_input_scale {
        top: -0.375rem;
        font-size: 0.6rem;
    }

    .typeMesureContainer {
        padding-left: 0;
        display: none;
    }

    .gestion_crise {
        width: 100%;
        justify-content: flex-start;
        position: relative;
        padding-top: 0.5rem;
    }

    .crise_dropdown {
        display: none !important;
    }

    .header_actions {
        justify-content: flex-start;
        padding-top: 0;
    }

    .header_actions nav {
        display: block;
    }

    button#btn_2019,
    button#btn_hier,
    button#btn_temps_reel,
    button#btn_1_jour,
    button#btn_3_jours {
        padding: 0 0.625rem !important;
    }

    .btn_signout {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
    }

    .esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
        position: relative !important;
        /* top: 30%; */
        height: 450px !important;
        width: min-content !important;
        left: -150% !important;
    }

    .esri-view-width-xsmall .esri-expand--auto .esri-expand__mask--expanded {
        background-color: transparent !important;
    }

    #btn-toggle-layer {
        width: 90% !important;
        height: 60px !important;
    }

    .left-panel-stat-container {
        position: initial;
        justify-content: center;
        flex-direction: inherit;
        width: 100%;
        height: 100%;
    }

    #btnStats,
    #btnWater,
    #btnBuilding,
    #btnStreet,
    #btnBag,
    #btnIncertMin,
    #btnIncertMax,
    #btn-search-ctrl {
        width: auto;
        height: initial;
        font-size: 1rem;
        padding: 0;
        background-color: transparent;
    }

    #btnBuilding svg,
    #btnBag svg {
        transform: scale(0.65);
    }

    #searchDiv {
        top: -100px !important;
        /*width: 100% !important;*/
    }

    .btn-container-layer {
        width: 100% !important;
        justify-content: space-evenly !important;
        background-color: var(--color-bg) !important;
        /* padding: 0.3125rem; */
        border-top-left-radius: 6px !important;
        border-top-right-radius: 6px !important;
    }

    .left-panel-stat-container.stats,
    .left-panel-stat-container.search {
        flex-grow: inherit;
        background-color: var(--color-light-grey);
        border-top-left-radius: 6px !important;
    }

    .left-panel-stat-container.search {
        flex-grow: inherit;
        background-color: var(--color-light-grey);
        border-top-right-radius: 6px !important;
    }

    .left-panel {
        width: 90%;
        flex-wrap: wrap;
        background-color: var(--color-bg-stats);
    }

    [data-title]:hover::before {
        display: none !important;
    }
}

/*@media only screen and (max-width: 815px) {*/
/*    !* div.map.esri-view {*/
/*          height: calc(100vh - 230px) !important;*/
/*      } *!*/
/*}*/

@media only screen and (max-width: 600px) {
    #btn-toggle-layer {
        height: 40px !important;
    }

    #btnBuilding svg,
    #btnBag svg {
        transform: scale(0.55);
    }

    .panel {
        width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 540px) {
    /*.esri-widget {*/
    /*  background-color: transparent !important;*/
    /*}*/
    .esri-widget--button.esri-widget.esri-interactive {
        background-color: white !important;
    }

    .esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
        /* left: -60% !important; */
        overflow: hidden !important;
    }
}

.btn_panel {
    border-radius: 25px;
}

[data-title] {
    /*outline: red dotted 1px; !*optional styling*!*/
    /*font-size: 30px; !*optional styling*!*/
    position: relative;
    cursor: help;
}

[data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    /* right: 48px; */
    top: -2rem;
    display: inline-block;
    padding: 0.1875rem 0.375rem;
    border-radius: 6px;
    color: var(--color-black);
    /*background-color: var(--color-bg);*/
    background-color: var(--color-light-grey);
    font-size: 0.75rem;
    white-space: nowrap;
}

#btn-layer-ctrl {
    position: absolute;
    right: 19px;
    bottom: 150px;
    width: 33px;
    height: 33px;
    z-index: 1;
}

#btn-toggle-layer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 80px;
    z-index: 1;
}

.btn-container-layer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    position: relative;
}

#btn-stats-ctrl {
    position: absolute;
    left: 18px;
    bottom: 25px;
    width: 33px;
    height: 33px;
    z-index: 1;
}

.tooltip {
    position: relative;
}

.tooltiptext {
    position: absolute;
    width: 60px;
    font-weight: bold;
    white-space: nowrap;
    display: none;
    background-color: #16ccbf;
    color: #fff;
    font-size: 0.75rem;
    border-radius: 4px;
    box-shadow: 1px 1px 20px #aaa;
    padding: 0.3125rem;
    z-index: 1000;
    transition: all 0.3s;
}

.tooltip:hover ~ .tooltiptext {
    display: block;
}

.tooltip ~ .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -0.3125rem;
    border-width: 5px;
    border-style: solid;
    border-color: #16ccbf transparent transparent transparent;
}

.esri-ui {
    z-index: 1 !important;
}

.esri-popup__main-container {
    /*margin-top: 2.5rem;*/
    margin-top: 7.8rem;
}

.esri-ui-top-left,
.esri-ui-top-right {
    flex-direction: row !important;
    top: 1rem !important;
}

.esri-ui-bottom-left {
    left: -10px !important;
    bottom: -10px !important;
}

.esri-search {
    margin-right: 1.25rem;
}

.esri-expand:before {
    content: "Légende";
    position: absolute;
    margin-left: -75px;
    color: var(--color-bg);
    z-index: 11;
    padding-top: 0.5rem;
}

.esri-expand {
    margin-left: 4.6875rem;
    position: relative;
}

.esri-expand__content--expanded {
    margin-left: 0 !important;
}

.esri-ui-top-left .esri-icon-expand,
.esri-ui-bottom-left .esri-icon-expand,
.esri-ui-top-left .esri-icon-collapse,
.esri-ui-bottom-left .esri-icon-collapse {
    transform: rotate(0deg) !important;
}

.esri-ui-top-left .esri-expand__content,
.esri-ui-top-right .esri-expand__content {
    top: 2.5rem !important;
}

.esri-ui-top-left .esri-expand__content,
.esri-ui-bottom-left .esri-expand__content {
    left: -268px !important;
    z-index: 9 !important;
}

/* Slider */
.MuiSlider-root {
    color: var(--color-disabled) !important;
    height: 4px !important;
    top: 12px !important;
    position: initial !important;
    padding: 0 !important;
}

.MuiSlider-marked {
    margin-bottom: 0.3125rem !important;
}

@media only screen and (min-width: 1025px) and (max-width: 1276px) {
    .esri-expand {
        position: absolute;
        top: 70px;
    }

    .esri-ui-top-left .esri-expand__content,
    .esri-ui-bottom-left .esri-expand__content {
        left: -75px !important;
    }

    .esri-popup__main-container {
        /*margin-top: 2.5rem;*/
        margin-top: 7.7rem;
    }
}

@media only screen and (max-width: 1024px) {
    .MuiSlider-marked {
        margin-bottom: 0.375rem !important;
    }

    .esri-ui-top-left,
    .esri-ui-top-right {
        flex-direction: row !important;
        top: 1rem !important;
    }
}

.MuiSlider-rail {
    opacity: 1 !important;
}

.MuiSlider-rail,
.MuiSlider-track,
.MuiSlider-mark {
    height: 4px !important;
}

.MuiSlider-thumb {
    width: 21px !important;
    height: 21px !important;
    background-color: var(--color-bg) !important;
    border: 3px solid var(--color-green) !important;
    margin-top: -0.5rem !important;
    z-index: 1 !important;
}

.MuiSlider-valueLabel {
    left: -30% !important;
}

.MuiSlider-markActive {
    opacity: 1 !important;
    background-color: var(--color-disabled) !important;
}

.MuiSlider-mark {
    height: 20px !important;
}

.MuiSlider-markLabel {
    margin-top: -0.5625rem !important;
    margin-left: 0.8125rem !important;
    font-family: var(--font-prompt-italic) !important;
    font-size: 0.75rem !important;
    color: var(--color-disabled) !important;
}

@media only screen and (max-width: 1024px) {
    .MuiSlider-markLabel {
        font-size: 0.5rem !important;
        margin-left: 0px !important;
        top: 38px !important;
    }
}

.MuiSlider-thumb.MuiSlider-active {
    box-shadow: none !important;
}

.PrivateValueLabel-circle-4,
.MuiSlider-valueLabel > span[class^="jss"] {
    width: 76px !important;
    height: 28.63px !important;
    border-radius: 6px !important;
    transform: none !important;
    background-color: var(--color-cyan) !important;
}

.PrivateValueLabel-label-5,
.MuiSlider-valueLabel > span[class^="jss"] > span[class^="jss"] {
    font-family: var(--font-prompt-bold) !important;
    font-size: 0.75rem !important;
    line-height: 0.875rem !important;
    transform: none !important;
    padding: 0.625rem !important;
}

.PrivateValueLabel-label-5::after,
.MuiSlider-valueLabel > span[class^="jss"] > span[class^="jss"]::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 15%;
    margin-left: -0.3125rem;
    border-width: 5px;
    border-style: solid;
    border-color: #16ccbf transparent transparent transparent;
}

/* Button Hier, Temps Réel, 1 jour, 3 jours */
button#btn_2019,
button#btn_hier,
button#btn_temps_reel,
button#btn_1_jour,
button#btn_3_jours {
    width: auto;
    height: 2rem;
    background-color: var(--color-green);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 6px;
    padding: 0 1.25rem;
    margin: 0.3125rem;
    font-size: 0.75rem;
    color: white;
}

div.map.esri-view {
    height: calc(100vh - 3rem);
}

.esri-view-width-xlarge .esri-popup__main-container {
    width: 400px !important;
}

.esri-ui-top-right .esri-component,
.esri-ui-top-left .esri-component {
    margin-right: 0.625rem !important;
    left: 0;
}

.esri-view .esri-view-surface--inset-outline:focus::after {
    outline: 0 !important;
}

.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
    bottom: 0;
}

/*#closeChart::before {*/
/*    content: "x";*/
/*    position: absolute;*/
/*    font-size: 1rem;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/

/*#closeChart {*/
/*    position: absolute;*/
/*    border-radius: 50%;*/
/*    top: 0;*/
/*    right: 0;*/
/*    padding: 0.125rem;*/
/*    margin: 0.25rem;*/
/*    background: var(--color-light-black);*/
/*    height: 2rem;*/
/*    width: 2rem;*/
/*    cursor: pointer;*/
/*    color: var(--color-bg);*/
/*}*/

/*#closeChart:hover {*/
/*    background: var(--color-dark-pink);*/
/*    color: var(--color-bg);*/
/*    cursor: pointer;*/
/*}*/

@media only screen and (min-width: 1024px) {
    .map {
        z-index: auto;
    }
}

.esri-scale-bar {
    bottom: 100px;
}

.testt {
    background-color: white;
    width: 200px;
    height: 200px;
}